/*
 * @Description: layout
 * @Author: shilin deng
 * @Date: 2021-06-17 17:38:09
 */
/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import LoginLayout from './tutor/logout-layout'
import Head from 'next/head'
import Router from 'next/router'
import bem from '../../utils/bem'
import API from '../../utils/api'
import {
  INDEX_SEO_CONFIG,
  CL_PAGES,
  LOGOUT_LINK,
  TUTOR_LOGOUT_PAGE,
  TUTOR_LOGIN_SEO_CONFIG,
  BLANK_LAYOUT_PAGE,
} from '../../utils/constant'
import UserHeader from './user/_header'
import TutorHeader from './tutor/_header'
import Header from './website/_header'
import Footer from './website/_footer'
import { StickyBar } from '../pages/home'

import { TURNITIN_AUTHORIZED_USERS } from '../../utils/constant'

const USER_LINK = [
  {
    name: '首页 ',
    key: 'dashboard',
    url: '/user/dashboard',
    rails: true,
  },
  {
    name: '学习服务 ',
    children: [],
  },
  {
    name: '生活服务 ',
    children: [],
  },
  {
    name: '课程表 ',
    key: 'timetable',
    url: '/timetable',
    rails: true,
  },
]
const TUTOR_LINK = [
  {
    label: 'Payment ',
    children: [
      {
        label: 'Upcoming',
        href: '/tutor/payments/upcoming',
      },
      {
        label: 'History',
        href: '/tutor/payments/history',
      },
    ],
  },
]
export default function Layout({ children, user, tutor, path }) {
  const [logoutTutorFlag, setLogoutTutorFlag] = useState(path)
  const [blankLayoutFlag, setBlankLayoutFlag] = useState(path)
  const [tutorFlag, setTutor] = useState(tutor)
  const [userFlag, setUser] = useState(user)
  const [userLt, setUserLt] = useState()
  const [impersonatingFlag, setImpersonating] = useState({})
  const [userLink, setUserLink] = useState()
  const [title, setTitle] = useState('荃程无忧')
  const [description, setDescription] = useState('')
  const [keywords, setKeywords] = useState('')
  const [cl, setCl] = useState('')
  const [location, setLocation] = useState('')
  const [tutorLink, setTutorLink] = useState([])
  // function crisp() {
  //   window.$crisp = []
  //   window.CRISP_WEBSITE_ID = '6db03630-f597-4b56-90ad-ea2c27a880a3'
  //   ;(function () {
  //     const s = document.createElement('script')
  //     s.src = 'https://client.crisp.chat/l.js'
  //     s.async = 1
  //     document.getElementsByTagName('head')[0].appendChild(s)
  //   })()
  // }
  function getTutorMenu() {
    let obj = []
    //gandue导师有合作方隐藏Payment
    if (!(tutor?.gandue && tutor?.hide_payment)) {
      obj = JSON.parse(JSON.stringify(TUTOR_LINK))
    }
    if (tutor.is_lt_enabled) {
      obj.unshift({
        label: 'Live Tutorial',
        href: '/tutor/live_tutorials',
      })
    }
    if (tutor.is_qa_enabled) {
      obj.unshift({
        label: 'Q&A',
        href: '/tutor/qa',
      })
    }
    if (TURNITIN_AUTHORIZED_USERS.some((v) => v.id === tutor.id)) {
      obj.unshift({
        label: 'Turnitin Check',
        href: '/tutor/tii',
      })
    }
    if (tutor.country_scope !== 'aunz') {
      obj.push({
        label: 'Help Desk',
        href: 'https://wiki.quanchengvip.com',
        target: '_blank',
      })
    }
    //QD-21 隐藏beans相关逻辑
    // if (
    //   tutor.is_lt_enabled &&
    //   tutor.stratification_enabled &&
    //   !tutor.is_hignest_level
    // ) {
    //   obj.push({
    //     label: 'Bean History',
    //     href: '/tutor/bean_transactions',
    //   })
    // }
    setTutorLink(obj)
  }
  function setHeaderLink(services) {
    // TODO: refactor 在前端判断处理
    const obj = JSON.parse(JSON.stringify(USER_LINK))
    if (services) {
      services.forEach((service) => {
        if (service.service_type === 'academic_service') {
          obj[1].children.push(service)
        } else if (service.service_type === 'life_service') {
          obj[2].children.push(service)
        } else {
          obj.push(service)
        }
      })
    }
    for (let i = 0; i < obj?.length; i++) {
      if (!obj[i].url && !obj[i].children?.length) {
        obj.splice(i, 1)
        i--
      }
    }
    setUserLink(obj)
  }
  async function init() {
    const path = Router.pathname
    let impersonatingMsg
    if (user && path === '/') {
      Router.push(bem.getRedirectUrl(user.status))
    }
    setLogoutTutorFlag(TUTOR_LOGOUT_PAGE.includes(Router.pathname))
    setBlankLayoutFlag(BLANK_LAYOUT_PAGE.includes(Router.pathname))
    if (user && path.startsWith('/user')) {
      const { impersonating, admin_user_id } = await API.getUserInfo()
      if (impersonating) {
        impersonatingMsg = `user-${user.id}-${admin_user_id}`
        setImpersonating({
          ...impersonatingFlag,
          user: impersonatingMsg,
        })
      } else {
        impersonatingMsg = `user-${user.id}`
      }
    } else if (tutor && path.startsWith('/tutor')) {
      const { impersonating, tutor, admin_user_id } = await API.getTutorInfo()
      if (impersonating) {
        impersonatingMsg = `tutor-${tutor.id}-${admin_user_id}`
        setImpersonating({
          ...impersonatingFlag,
          tutor: impersonatingMsg,
        })
      } else {
        impersonatingMsg = `tutor-${tutor.id}`
        // if (tutor.country_scope !== 'aunz') {
        //   crisp()
        // }
      }
    } else {
      setUser(false)
      setTutor(false)
    }
    if (
      !process.env.NEXT_PUBLIC_IS_CN &&
      process.env.NODE_ENV !== 'development'
    ) {
      import('../../utils/segment').then(() => {
        const { analytics } = window
        if (impersonatingMsg) {
          analytics.identify(impersonatingMsg)
        }
      })
    }
  }
  function setWebTitle() {
    ;[...LOGOUT_LINK].map((v) => {
      if (v.children) {
        v.children.map((val) => {
          if (val.href === Router.pathname) {
            setLocation(val.location)
            setTitle(`荃程无忧 | ${val.title ? val.title : val.label}`)
            setDescription(val.description)
            setKeywords(val.keywords)
          }
          return true
        })
      } else {
        if (v.href === Router.pathname) {
          setLocation(v.location)
          setTitle(`荃程无忧 | ${v.title ? v.title : v.label}`)
          setDescription(v.description)
          setKeywords(v.keywords)
        }
      }
      return true
    })
    if (Router.pathname === '/') {
      setTitle(INDEX_SEO_CONFIG.title)
      setDescription(INDEX_SEO_CONFIG.description)
      setKeywords(INDEX_SEO_CONFIG.keywords)
    }
    if (Router.pathname === '/tutor/login') {
      setTitle(TUTOR_LOGIN_SEO_CONFIG.title)
      setDescription(TUTOR_LOGIN_SEO_CONFIG.description)
      setKeywords(TUTOR_LOGIN_SEO_CONFIG.keywords)
    }
    if (user) {
      setTitle('荃程无忧')
    }
  }
  function setPageCl() {
    CL_PAGES.map((page) => {
      if (page.href === Router.pathname) {
        if (page.cl) {
          setCl(page.cl)
        }
        if (page.location) {
          setLocation(page.location)
        }
      }
      return true
    })
  }
  function initImpersonate() {
    if (Object.keys(impersonatingFlag).length) {
      const ele =
        document.getElementById('impersonatingDom') ||
        document.createElement('div')
      ele.id = 'impersonatingDom'
      ele.className = 'impersonating'
      ele.innerHTML = 'Impersonating: '
      if (impersonatingFlag.user) {
        ele.innerHTML += `User: ${user?.id} <a target="" href="/users/${user?.id}/stop_impersonate?token=DONutske2015">Stop</a><br />`
      }
      if (impersonatingFlag.tutor) {
        ele.innerHTML += `QuanChengMentor: ${tutor?.id} `
        const a = document.createElement('a')
        a.innerText = 'Stop'
        a.target = ''
        a.href = `/easyke_mentors/${tutor?.id}/stop_impersonate?token=DONutske2015`
        ele.appendChild(a)
      }
      document.body.appendChild(ele)
    }
  }
  useEffect(() => {
    const path = window.location.pathname
    if (tutor && path.startsWith('/tutor')) {
      setTutor(true)
      getTutorMenu()
    }
    if (user && path.startsWith('/user')) {
      if (
        (path.startsWith('/user/live_tutorials') &&
          path !== '/user/live_tutorials' &&
          path !== '/user/live_tutorials/bookings') ||
        path === '/user/deferred'
      ) {
        setUserLt(true)
      }
      setUser(true)
      setHeaderLink(user.services)
    }
    init()
    setWebTitle()
    setPageCl()
  }, [user, tutor])
  useEffect(() => {
    initImpersonate()
  }, [impersonatingFlag])
  return (
    <div>
        <Head>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="location" content={location} />
          <meta name="referrer" content="never" />
          <link rel="shortcut icon" href="/favicon.ico" />
        </Head>
        {!blankLayoutFlag &&
          (logoutTutorFlag ? (
            <LoginLayout>{children}</LoginLayout>
          ) : (
            // <BaseLayout user={user} tutor={tutor}> {children} </BaseLayout>
            <>
              {userFlag ? (
                <UserHeader user={user} userLink={userLink} />
              ) : tutorFlag ? (
                <TutorHeader tutor={tutor} menu={tutorLink} />
              ) : (
                <Header />
              )}
            </>
          ))}
        {!logoutTutorFlag && (
          <div location={location} className={cl}>
            <div className="content l-container-fixed">{children}</div>
          </div>
        )}
        {!Object.keys(impersonatingFlag).length &&
          !tutorFlag &&
          !logoutTutorFlag &&
          !blankLayoutFlag &&
          !userLt && <Footer />}
        {!user && !tutorFlag && !logoutTutorFlag && !blankLayoutFlag && (
          <StickyBar />
        )}
      </div>
  )
}
